import type { MetaFunction } from "@vercel/remix"
import type { MetaDescriptor } from "@remix-run/react"
import {
  Links,
  Meta,
  Outlet,
  Scripts,
  ScrollRestoration,
} from "@remix-run/react"
import { withSentry } from "@sentry/remix"
import { Analytics } from "@vercel/analytics/react"

export const meta: MetaFunction = (): Array<MetaDescriptor> => [
  { charSet: "utf-8" },
  { name: "author", content: "Ligonier Ministries" },
  { title: "RefNet Stream" },
  { name: "viewport", content: "width=device-width,initial-scale=1" },
  {
    name: "description",
    content:
      "Free 24-hour Christian internet radio. Listen to trustworthy preaching & teaching from trusted Bible teachers and theologians such as R.C. Sproul, Sinclair Ferguson, W. Robert Godfrey, John MacArthur, Stephen Nichols, Burk Parsons, Derek Thomas, & many more.",
  },
]

function App() {
  return (
    <html lang="en">
      <head>
        <Meta />
        <Links />
      </head>
      <body style={{ background: "#262626", textAlign: "center" }}>
        <Analytics />
        <Outlet />
        <ScrollRestoration />
        <Scripts />
      </body>
    </html>
  )
}

export default withSentry(App)
